<template lang="pug">
portal(to="cm-accountUsername")
  v-icon(small @click="toggle").copy-arrow mdi-menu-down
  v-dialog(v-model="showing" :max-width="600")
    v-card
      v-card-title CBR user filter
      v-card-text
        v-select(
          :items="$store.state.cbrUser.items"
          v-model="filters.cbr_user"
          item-text="username"
          item-value="ID"
          field="cbrUsers"
          label="CBR user"
          multiple
        )
      v-card-actions
        v-spacer
        v-btn(color="error" text @click="toggle") Close
        v-btn(color="success" text @click="submit") Apply 
</template>

<script>
import showingMixin from '@/mixins/showing.mixin'
export default {
  name: 'CMFiltersCBRUser',
  mixins: [showingMixin],

  props: {
    filters: Object,

  },

  methods: {
    submit() {
      this.updateShowing(false)
      this.$emit('changed')
    }
  }
}
</script>